<template>
  <the-base-table
    ref="baseTable"
    url="medical_services/"
    singularName="Servicio Médico"
    pluralName="Servicios médicos"
    :headers="headers"
    :filters="filters"
    :objectEdited.sync="medicalServiceEdited"
    :objectDefault="medicalServiceDefault"
    :showActionsColumn="true"
    :edit="true"
  >
    <template v-slot:form="{ isEditing }">
      <medical-service-form
        :is-editing="isEditing"
        :medical_service="medicalServiceEdited"
        @canceled="stopEditing()"
        @saved="refresh()"
        @error="error($event)"
      />
    </template>
    <template v-slot:toolbar-append>
      <v-container>
        <v-layout wrap>
          <v-flex xs12 sm6 md4>
            <v-autocomplete
              name="category"
              :items="categories"
              :loading="loadingCategory"
              v-model="filter.category"
              label="Categorias"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
    <template v-slot:row="{ item }">
      <td class="text-center">{{ item.id }}</td>
      <td>{{ item.service }}</td>
      <td>{{ item.get_category_display }}</td>
      <td class="text-center">
        {{ item.get_last_ms_bonus_percentage | percentage }}
      </td>
    </template>
  </the-base-table>
</template>

<script>
import TheBaseTable from '@/components/base/BaseTable'
import MedicalServiceForm from '@/components/medical_services/MedicalServiceForm'
import Utils from '@/utils'

export default {
  data: () => ({
    categories: [],
    loadingCategory: true,
    filter: {
      category: null,
    },
    medicalServiceEdited: {
      service: '',
      category: '',
      percentage: 0,
    },

    headers: [
      { text: 'ID', value: 'id', align: 'center' },
      { text: 'Servicio médico', value: 'service', align: 'center' },
      { text: 'Categoría', value: 'get_category_display', align: 'center' },
      {
        text: 'Porcentaje de bonificación (máximo)',
        value: 'get_last_ms_bonus_percentage',
        align: 'center',
        width: '25%',
      },
    ],
  }),
  mounted() {
    if (this.axios.defaults.headers.common.Authorization) {
      this.axios
        .get(this.$api('ms_categories'))
        .then(data => {
          data.data.forEach(Category => {
            this.categories.push({
              value: Category.id,
              text: Category.category,
            })
          })
          this.categories.push({
            value: null,
            text: 'Todos',
          })
          this.loadingCategory = false
        })
        .catch(error => console.log(error))
    }
  },
  computed: {
    filters() {
      return this.filter
    },
    medicalServiceDefault() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      ) {
        return {
          id: -1,
          service: '',
          category: '',
        }
      }
      return undefined
    },
  },
  methods: {
    refresh() {
      this.$refs.baseTable.isEditing = false
      this.$refs.baseTable.fetch()
    },
    stopEditing() {
      this.$refs.baseTable.isEditing = false
      this.$refs.baseTable.resetOnOpen = true
    },
    error(event) {
      console.log(event)
    },
  },
  components: {
    TheBaseTable,
    MedicalServiceForm,
  },
}
</script>
