<template>
  <div wrap class="elevation-1">
    <v-data-table
      @input="$emit('input', $event)"
      :value="value"
      :headers="extendedHeaders"
      :items="items"
      item-key="id"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar
          wrap
          dark
          color="secondary lighten-2"
          :style="getToolbarHeight()"
        >
          <v-toolbar-title v-if="!hideTitle">{{
            title || pluralName
          }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer />
          <slot name="toolbar-prepend" />
          <v-dialog
            v-if="isEditable"
            v-model="isEditing"
            persistent
            max-width="800px"
          >
            <template v-if="create" v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                @click="editItem(objectDefault)"
                >Agregar</v-btn
              >
            </template>
            <slot name="form" :is-editing="isEditing" />
          </v-dialog>
        </v-toolbar>
        <slot name="toolbar-append" />
      </template>

      <!-- <template v-if="showActionsHeader" v-slot:item.actions="{ item }">
      <template v-if="isEditable">
      <v-icon
        v-if="edit"
        small
        class="mr-2"
        key="edit"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        v-if="remove"
        small
        key="delete"
        @click="deleteItemDialog(item)"
      >
        mdi-delete
      </v-icon>
      </template>
      <slot name="actions"/>
    </template> -->

      <template v-slot:item="{ item }">
        <tr>
          <slot name="row" :selected="item.selected" :item="item" />
          <td v-if="showActionsHeader" class="text-center">
            <template v-if="isEditable">
              <slot name="actions" :item="item"></slot>
              <v-tooltip v-if="edit" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    rounded
                    color="accent"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(item)"
                    key="edit"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip v-if="remove" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    rounded
                    color="error"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItemDialog(item)"
                    key="delete"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>
              <v-icon
                v-if="false"
                small
                class="mr-2"
                @click="editItem(item)"
                key="edit"
                >mdi-pencil</v-icon
              >
              <v-icon
                v-if="false"
                small
                @click="deleteItemDialog(item)"
                key="delete"
                >mdi-delete</v-icon
              >
            </template>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <span>No hay {{ pluralName.toLowerCase() }}.</span>
      </template>
      <template v-if="hasFooter" v-slot:footer>
        <slot name="footer" :items="items" />
      </template>
    </v-data-table>

    <v-dialog v-model="isDeleting" persistent max-width="320">
      <v-card>
        <v-card-title class="headline">Confirmación</v-card-title>
        <v-card-text>¿Está seguro que desea eliminar este ítem?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="isDeleting = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="success darken-1"
            text
            @click="deleteItem(objectDeleted)"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  props: {
    url: String,
    pluralName: String,
    headers: Array,
    title: {
      required: false,
    },
    objectDefault: {
      type: Object,
      default: null,
    },
    objectEdited: {
      type: Object,
      default: null,
    },
    filters: {
      type: Object,
      default: null,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    create: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    remove: {
      type: Boolean,
      default: true,
    },
    showActionsColumn: {
      required: false,
    },
    value: {
      type: Array,
    },
    toolbarHeight: {
      type: String,
      required: false,
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    dontWatchFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isEditing: false,
      isDeleting: false,
      objectDeleted: {},
      pagination: {
        rowsPerPage: 10,
      },
      loading: false,
      totalItems: 0,
    }
  },
  computed: {
    extendedHeaders() {
      const eHeaders = [...this.headers]
      if (this.showActionsHeader) {
        eHeaders.push({
          text: 'Acciones',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '20%',
        })
      }
      return eHeaders
    },
    showActionsHeader() {
      return typeof this.showActionsColumn !== 'undefined'
        ? this.showActionsColumn
        : this.isEditable
    },
    isEditable() {
      return this.objectDefault !== null && this.objectEdited !== null
    },
  },
  watch: {
    pagination: {
      handler() {
        this.fetch()
      },
    },
    filters: {
      deep: true,
      handler() {
        if (!this.dontWatchFilters) {
          this.fetch()
        }
      },
    },
    detailed() {
      this.fetch()
    },
  },
  methods: {
    fetch() {
      if (this.url && this.axios.defaults.headers.common.Authorization) {
        this.loading = true
        return this.axios
          .get(
            this.$api(this.url, {
              page_size: this.pagination.rowsPerPage,
              page: this.pagination.page,
              ...this.filters,
              ...(this.detailed && { detailed: null }),
            })
          )
          .then(({ data }) => {
            this.totalItems = data.count
            this.items = data
          })
          .catch(error => console.log(error.response))
          .then(() => {
            this.loading = false
          })
      }
    },
    editItem(item) {
      this.$emit('update:objectEdited', Object.assign({}, item))
      this.isEditing = true
    },
    deleteItemDialog(item) {
      this.objectDeleted = item
      this.isDeleting = true
    },
    deleteItem(item) {
      this.isDeleting = false
      this.axios
        .delete(this.$api(this.url + item.id))
        .then(() => {
          this.$emit('deleted', item)
          this.$store.dispatch('message/success', 'Recurso eliminado con éxito')
          this.fetch()
        })
        .catch(error => console.log(error.response))
    },
    getToolbarHeight() {
      if (this.toolbarHeight) {
        return { height: this.toolbarHeight }
      } else {
        return {}
      }
    },
  },
}
</script>
