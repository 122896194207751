<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ computedTitle }}</span>
    </v-card-title>

    <validation-observer v-slot="{ handleSubmit }" ref="observer">
      <v-form ref="form" @submit.prevent="handleSubmit(save)">
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <slot :form-data="formData" :mode="mode" :loading="loading" />
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <slot name="extra_buttons_preppend" />
          <v-spacer></v-spacer>
          <v-btn
            v-if="!noCancel"
            color="error darken-1"
            text
            @click="$emit('canceled')"
            >{{ cancelName }}</v-btn
          >
          <v-btn
            v-if="!noSave"
            color="success darken-1"
            text
            type="submit"
            :loading="loading"
            >Guardar</v-btn
          >
          <slot name="extra_buttons_append" />
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import formMixin from '@/mixins/form'

export default {
  mixins: [formMixin],
  props: {
    singularName: String,
    title: {
      required: false,
    },
    cancelName: { required: false, default: 'Cancelar' },
    noCancel: {
      required: false,
    },
    noSave: {
      required: false,
    },
  },
  computed: {
    computedTitle() {
      return typeof this.title !== 'undefined'
        ? this.title
        : (this.mode === 'create' ? 'Crear ' : 'Editar ') + this.singularName
    },
  },
}
</script>
