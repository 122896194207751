<template>
  <div id="medicalServiceIndex">
    <medical-service-table />
  </div>
</template>

<script>
import MedicalServiceTable from '@/components/medical_services/MedicalServiceTable'
export default {
  components: { MedicalServiceTable },
  data () {
    return {}
  },
  computed: {
  }
}
</script>
