export default {
  data() {
    return {
      formData: {},
      loading: false,
    }
  },
  props: {
    url: String,
    object: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    mustFetchFormData: {
      type: Boolean,
      default: false,
    },
    multipart: {
      type: Boolean,
      default: false,
    },
    usePatch: {
      type: Boolean,
      default: false,
    },
    resetOnOpen: {
      type: Boolean,
      default: false,
    },
    forcePost: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mode() {
      return this.object.id === -1 ? 'create' : 'edit'
    },
  },
  watch: {
    isEditing: {
      immediate: true,
      handler(isEditing) {
        if (isEditing) {
          if (this.$refs.observer && this.resetOnOpen) {
            this.$refs.observer.reset()
          }

          if (this.mustFetchFormData) {
            this.fetchFormData()
          }

          this.$nextTick().then(() => {
            this.$refs.observer.reset()
          })
        }
      },
    },
  },
  methods: {
    fetchFormData() {
      return this.mode === 'create'
        ? this.fetchCreateFormData()
        : this.fetchEditFormData()
    },
    fetchCreateFormData() {
      this.loading = true
      this.$emit('form-loading:start', 'create')
      this.$emit('create-form-loading:start')

      return this.axios
        .get(this.$api(`${this.url}/create-form/`))
        .then(({ data }) => {
          this.formData = data
          this.$emit('form-loading:success', 'create', data)
          this.$emit('create-form-loading:success', data)
        })
        .catch(error => {
          this.$emit('error', error)
          this.$emit('form-loading:error', 'create', error)
          this.$emit('create-form-loading:error', error)
        })
        .then(() => {
          return this.$nextTick(() => {
            this.loading = false
            this.$emit('form-loading:end', 'create')
            this.$emit('create-form-loading:end')
          })
        })
    },
    fetchEditFormData() {
      this.loading = true
      this.$emit('form-loading:start', 'edit')
      this.$emit('edit-form-loading:start')

      return this.axios
        .get(this.$api(`${this.url}/${this.object.id}/edit-form/`))
        .then(({ data }) => {
          this.formData = data
          this.$emit('form-loading:success', 'edit', data)
          this.$emit('edit-form-loading:success', data)
        })
        .catch(error => {
          this.$emit('error', error)
          this.$emit('form-loading:error', 'edit', error)
          this.$emit('edit-form-loading:error', error)
        })
        .then(() => {
          return this.$nextTick(() => {
            this.loading = false
            this.$emit('form-loading:end', 'edit')
            this.$emit('edit-form-loading:end')
          })
        })
    },
    save() {
      return this.$refs.observer.validate().then(result => {
        if (result) {
          return this.mode === 'create' || this.forcePost
            ? this.create()
            : this.update()
        }
      })
    },
    create() {
      this.loading = true
      this.$emit('form-save:start', 'create')
      this.$emit('create-form-save:start')

      return this.axios
        .post(this.$api(this.url), this.proccessObject(this.object), {
          ...(this.multipart && {
            headers: { 'Content-Type': 'multipart/form-data' },
          }),
        })
        .then(({ data }) => {
          this.$emit('saved', data)
          this.$emit('form-save:success', 'create', data)
          this.$emit('create-form-save:success', data)
          this.$store.dispatch(
            'message/success',
            'El recurso ha sido creado con éxito'
          )
        })
        .catch(error => {
          this.$emit('error', error)
          this.$emit('form-save:error', 'create')
          this.$emit('create-form-save:error')
        })
        .then(() => {
          return this.$nextTick(() => {
            this.loading = false
            this.$emit('form-save:end', 'create')
            this.$emit('create-form-save:end')
          })
        })
    },
    proccessObject(object) {
      if (this.multipart) {
        const formData = new FormData()
        for (const attr in object) {
          // TO DO: Validar datos blob para agregar nombre archivo
          if (
            typeof object[attr] === 'object' &&
            !(object[attr] instanceof Array) &&
            !(object[attr] instanceof File)
          ) {
            for (const subattr in object[attr]) {
              formData.append(attr + '.' + subattr, object[attr][subattr])
            }
          } else {
            formData.append(attr, object[attr][0])
          }
        }

        return formData
      }

      return object
    },
    update() {
      this.loading = true
      this.$emit('form-save:start', 'edit')
      this.$emit('edit-form-save:start')

      return this.axios[this.usePatch ? 'patch' : 'put'](
        this.$api(this.url + this.object.id + '/'),
        this.proccessObject(this.object),
        {
          ...(this.multipart && {
            headers: { 'Content-Type': 'multipart/form-data' },
          }),
        }
      )
        .then(({ data }) => {
          this.$emit('saved', data)
          this.$emit('form-save:success', 'edit', data)
          this.$emit('edit-form-save:success', data)
          this.$store.dispatch(
            'message/success',
            'El recurso ha sido actualizado con éxito'
          )
        })
        .catch(error => {
          this.$emit('error', error)
          this.$emit('form-save:error', 'edit')
          this.$emit('edit-form-save:error')
        })
        .then(() => {
          return this.$nextTick(() => {
            this.loading = false
            this.$emit('form-save:end', 'edit')
            this.$emit('edit-form-save:end')
          })
        })
    },
  },
}
