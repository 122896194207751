<template>
  <the-base-form
    :url="url"
    :object="medical_service"
    :is-editing="isEditing"
    singularName="Servicio médico"
    @canceled="$emit('canceled')"
    @saved="$emit('saved')"
    @error="$emit('error', $event)"
  >
    <v-flex xs12 sm12 md12>
      <ValidationProvider
        name="Servicio médico"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          name="medical_service"
          label="Servició médico"
          type="text"
          :value="medical_service.service"
          v-model="medical_service.service"
          :error-messages="errors"
          hint="Servicio medico"
        ></v-text-field>
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <ValidationProvider
        name="Categoria de servicio médico"
        rules="required"
        v-slot="{ errors }"
      >
        <v-autocomplete
          name="medical_service_categorie"
          :items="categorias"
          v-model="medical_service.category"
          label="Categoría servicio médico"
          :error-messages="errors"
          hint="Categoria"
        ></v-autocomplete>
      </ValidationProvider>
    </v-flex>
  </the-base-form>
</template>

<script>
import TheBaseForm from '@/components/base/BaseForm'

export default {
  data: () => ({
    categorias: [],
  }),
  components: {
    TheBaseForm,
  },
  props: {
    medical_service: Object,
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    url() {
      return 'medical_services/'
    },
  },
  created() {
    this.axios
      .get(this.$api('ms_categories'))
      .then(data => {
        data.data.forEach(MedicalServiceCategorie => {
          this.categorias.push({
            value: MedicalServiceCategorie.id,
            text: MedicalServiceCategorie.category,
          })
        })
      })
      .catch(error => console.log(error))
  },
  methods: {},
}
</script>
